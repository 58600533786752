import * as React from 'react';
import styles from './Sprints.module.scss';
import classNames from 'classnames';
import { getTasksByIds } from '~/utils/utils';
import TaskCard from '~/components/Cards/Task/TaskCard';
import Scroll from '~/components/Scroll/Scroll';
import { LOADERS, LOADERS_TYPE } from '~/const';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { DROPPABLE_ID } from '~/containers/Projects/Sprints/Sprints';
import Circle from '~/components/Loader/Circle/Circle';
import { useAppSelector } from '~/store';
import { selectLoader } from '~/store/slices/app/slice';
import { selectTasks } from '~/store/slices/task/slice';

interface IFC {
    sprintId?: number;
    openStatuses?: number[];
    tasksIds: number[];
}

const SprintTasks = ({ sprintId, openStatuses, tasksIds }: IFC) => {
    const tasks = useAppSelector((state) => selectTasks(state));
    const isSprintTasksListLoading = useAppSelector((state) =>
        selectLoader(state, LOADERS.SPRINT_TASKS_LIST, LOADERS_TYPE.LOADING),
    );
    const ref = React.useRef(null);

    return (
        <Droppable droppableId={DROPPABLE_ID.TASKS_SPRINT}>
            {(provided) => (
                <div
                    className={styles.sprintTasks}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {sprintId && isSprintTasksListLoading ? (
                        <Circle className={styles.loader} />
                    ) : (
                        <>
                            <div
                                className={classNames(
                                    'withoutScrollBar',
                                    styles.scrollable,
                                    styles.tasksSprintScrollable,
                                )}
                                ref={ref}
                            >
                                {getTasksByIds(tasks, tasksIds, undefined, openStatuses).map(
                                    (task, key) => (
                                        <Draggable
                                            draggableId={`${task.id}`}
                                            index={key}
                                            key={task.id}
                                        >
                                            {(provided) => (
                                                <div
                                                    className={styles.cardWrapper}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <TaskCard
                                                        taskId={task.id}
                                                        key={key}
                                                        className={styles.card}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ),
                                )}
                            </div>
                            <Scroll element={ref} className={styles.scroll} />
                        </>
                    )}
                </div>
            )}
        </Droppable>
    );
};

export default SprintTasks;
