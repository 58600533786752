import { Centrifuge } from 'centrifuge';
import { SOCKET_MODEL, META } from '~/const';
import store, { useAppSelector } from '~/store';
import { prepareTaskFromServer } from '~/utils/tasks';
import { selectAppMeta } from '~/store/slices/app/slice';
import { update } from '~/store/slices/task/slice';
import { selectTaskListIds } from '~/store/slices/taskList/slice';

export const socket = (accountId: number) => {
    const centrifuge = new Centrifuge(`${process.env.REACT_APP_WS_HOST}`, {
        token: localStorage.getItem('socket'),
    });

    const sub = centrifuge.newSubscription(`channel_${accountId}`);

    sub.on('publication', (ctx) => {
        const data = ctx.data.data;
        switch (ctx.data.model) {
            case SOCKET_MODEL.TASK:
                const task: ITask = prepareTaskFromServer(data, store.dispatch);
                store.dispatch(update({ ...task }));

                const taskListIds = useAppSelector((state) => selectTaskListIds(state));
                // @ts-ignore
                const projects = store.getState().reducerProjects.projects;

                if (!taskListIds.includes(task.id)) {
                    const groupId = useAppSelector((state) =>
                        selectAppMeta(state, META.CURRENT_GROUP),
                    );
                    if (
                        !groupId ||
                        (projects && task.project && groupId === projects[task.pid].group_id)
                    ) {
                        //TODO: update taskListIds (refresh current list)
                        //store.dispatch(_updateTaskList([task.id], taskList.totalCount + 1, true));
                    }
                }

                break;
            case SOCKET_MODEL.TIMING:
                store.dispatch(update(data));
                break;
            case SOCKET_MODEL.NOTIFICATION:
                store.getState().notifications.totalCount =
                    store.getState().notifications.totalCount + 1;

                store.dispatch({ type: `notifications/add`, payload: [data] });
                break;
            default:
                break;
        }
    });

    sub.subscribe();

    centrifuge.connect();
};
