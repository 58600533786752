import { DATE_FORMAT, ORDER_BY } from '~/const';
import { prepareDateToLocal } from '~/utils/utils';
import { Dispatch } from 'react';
import store from '~/store';
import { add as addUsers } from '~/store/slices/user/slice';
import { update } from '~/store/slices/sprint/slice';

export const tableConfig = [
    { name: 'title', title: 'Заголовок', orderby: true },
    { name: 'type', title: 'Тип проекта' },
    { name: 'cdate', title: 'Дата старта' },
    { name: 'users', title: 'Команда' },
];

export const tableUsersConfig = [
    { name: 'name', title: 'Имя' },
    { name: 'email', title: 'E-mail' },
    { name: 'roleId', title: 'Роль' },
    { name: 'controls', title: '' },
];

export const defaultOptions = {
    filters: {
        status: 'active',
    },
    orderby: ['title', ORDER_BY.ASC],
};

/** TODO: refactor this **/
export const prepareProjectFromServer = (project: any) => {
    if (project?.cdate) {
        project.cdate = prepareDateToLocal(project?.cdate, DATE_FORMAT);
    }

    if (project?.creator) {
        //store.dispatch(addUsers([project.creator]));
        project.creator = project.creator?.id || project.creator;
    }

    if (project?.users) {
        //dispatch(addUsers(project.users));
        project.users = project.users?.map((user: IUser) => user?.id || user);
    }

    !project.title?.trim().length && (project.title = 'Безымяный');

    if (project?.sprints) {
        project.sprints.forEach((sprint: ISprint) => {
            const title = `${
                new DOMParser().parseFromString(sprint.title, 'text/html').documentElement
                    .textContent
            }`;

            // store.dispatch(
            //     update([
            //         {
            //             ...sprint,
            //             title,
            //         },
            //     ]),
            // );
        });
        project.sprints = project.sprints.map((sprint: ISprint) => sprint.id);
    }

    if (project?.statuses) {
        const index = project.statuses.findIndex((s: IStatus) => s.is_open);
        if (index !== -1) {
            project.statuses[index]['null'] = true;
        }
    }

    return project;
};
