import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    apiApproveTiming,
    apiCancelTiming,
    apiCreateComment,
    apiGetTask,
    apiGetTaskLogs,
    apiGetTasksTiming,
    apiGetTaskTimings,
    apiUpdateComment,
    apiUpdateTask,
    apiUpdateTaskTiming,
    apiUploadFilesToComment,
    createTask,
} from '~/api/task';
import {
    DATE_FORMAT_HHMM,
    MEET_STATUSES,
    SERVER_DATE_FORMAT,
    TASK_FIELDS,
    TASK_TYPES,
} from '~/const';
import { prepareDateToServer } from '~/utils/utils';
import { put } from '~/utils/api';
import moment from 'moment/moment';

const getTask = createAsyncThunk('tasks/add', async (id: number) => {
    return await apiGetTask(id);
});

const newTask = createAsyncThunk(
    'tasks/create',
    async (task: { project_id: string; title: string }) => {
        return await createTask(task);
    },
);

const updateTask = createAsyncThunk(
    'tasks/update',
    async (data: { data: { [field: string]: any }; task: ITask | number }) => {
        if (typeof data.task === 'number') {
            return await apiUpdateTask(data.task, data.data);
        }

        const key = Object.keys(data.data)[0];
        const isDate = key === TASK_FIELDS.EDATE || key === TASK_FIELDS.WDATE;
        const itAMeet = data.task?.type === TASK_TYPES.MEETS;

        if (isDate) {
            data.data[key] = prepareDateToServer(Object.values(data.data)[0], undefined, itAMeet);
        }

        return await apiUpdateTask(data.task.id, data.data);
    },
);

const updateMeetStatus = createAsyncThunk(
    'tasks/updateMeetStatus',
    async (data: { status: 1 | 2 | 3; taskId: number; userId: number }) => {
        return await put(`tasks/${data.taskId}/assignees/${MEET_STATUSES[data.status]}`);
    },
);

const getTaskLogs = createAsyncThunk('tasks/addLogs', async (id: number) => {
    return await apiGetTaskLogs(id);
});

const getTaskTimings = createAsyncThunk('tasks/addTimings', async (id: number) => {
    return await apiGetTaskTimings(id);
});

const updateTaskTiming = createAsyncThunk(
    'tasks/updateTimings',
    async (data: { taskId: number; timingId: number; data: any }) => {
        return await apiUpdateTaskTiming(data.taskId, data.timingId, data.data);
    },
);

const approveTiming = createAsyncThunk(
    'tasks/approveTimings',
    async (data: { taskId: number; timingId: number }) => {
        return await apiApproveTiming(data.taskId, data.timingId);
    },
);

const cancelTiming = createAsyncThunk(
    'tasks/cancelTimings',
    async (data: { taskId: number; timingId: number }) => {
        return await apiCancelTiming(data.taskId, data.timingId);
    },
);

const addTaskTimings = createAsyncThunk('tasks/addTimings', async (date: string) => {
    return await apiGetTasksTiming(moment(date, DATE_FORMAT_HHMM).format(SERVER_DATE_FORMAT));
});

const updateComment = createAsyncThunk(
    'tasks/updateComment',
    async (data: { taskId: number; commentId: number; message: string; range: number }) => {
        return await apiUpdateComment(data.taskId, data.commentId, {
            message: data.message,
            range: data.range,
        });
    },
);

const createComment = createAsyncThunk(
    'tasks/createComment',
    async (data: { taskId: number; message: string; range: number }) => {
        return await apiCreateComment(data.taskId, {
            message: data.message,
            range: data.range,
        });
    },
);

const uploadFilesToComment = createAsyncThunk(
    'tasks/uploadFilesToComment',
    async (data: { taskId: number; commentId: number; files: FormData }) => {
        return await apiUploadFilesToComment(data.taskId, data.commentId, data.files);
    },
);

export {
    getTask,
    newTask,
    updateTask,
    updateMeetStatus,
    getTaskLogs,
    getTaskTimings,
    updateTaskTiming,
    approveTiming,
    cancelTiming,
    addTaskTimings,
    updateComment,
    uploadFilesToComment,
    createComment,
};
